.back-button {
  padding-left: 0 !important;
}

:hover.back-button {
  background-color: white !important;
}

.button-content {
  display: flex;
  gap: 8px;
  align-items: center;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  color: #444444;
}

.modal-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 150% !important;
}

.modal-divider {
  border: 1px solid #D0D0CE;
  margin: 0;
}

.modal-description {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.new-template-modal-footer {
  display: flex;
  padding: 24px;
  background-color: #F8F8F8;
  border-bottom-left-radius:  12px;
  border-bottom-right-radius:  12px;
  justify-content: space-between;
}

.new-template-modal-close-button {
  white-space: nowrap !important;
  border-color: #FFBD4C !important;
  background-color: white !important;
  color: #444444 !important;
  border-radius: 2px !important;
  width: 173px !important;
  height: 45px !important;
}

.new-template-modal-keep-button {
  white-space: nowrap !important;
  border-color: #FFBD4C !important;
  color: #444444 !important;
  background-color: #FFBD4C !important;
  border-radius: 2px !important;
  width: 231px !important;
  height: 45px !important;
}
