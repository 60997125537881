.emoji-mart-anchor {
  cursor: pointer;
}

.emoji-mart-category-label {
  cursor: auto;
}

.emoji-mart-category-label span {
  padding: 10px;
  color: #858585;
  font-weight: 600;
}
.emoji-mart-title-label {
  font-size: 18px;
}

.emoji-mart-emoji li {
  cursor: pointer;
}

.emoji-mart-category-list {
  cursor: auto;
}

.emoji-mart-category .emoji-mart-emoji span {
  cursor: pointer;
}

.emoji-mart-preview-name {
  display: none;
}