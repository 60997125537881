.step-content {
  align-items: center;
  row-gap: 16px;
  font-size: 14px;
  color: black;
  white-space: nowrap;
}

.circle-active-step {
  color: #444444;
  font-size: 12px;
  display: flex;
  height: 24px;
  width: 24px;
  padding: 0px 7px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #FFBD4C;
}

.circle-step {
  color: #444444;
  font-size: 12px;
  display: flex;
  height: 24px;
  width: 24px;
  padding: 0px 7px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #CBD0D9;
}

.line {
  height: 1px;
  background-color: #BDBDBD;
  width: 100%;
}

.modal-step {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 494px !important;
  background-color: white;
  box-shadow: 24px;
  border-radius: 12px;
}