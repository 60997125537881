.monitoring-teams-cards {
  display: grid !important;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

  margin-top: 16px;
}

.monitoring-teams-cards-no-data {
  padding: 24px;
  margin-top: 16px;
  
  background-color: #F5F6F7;
  border-radius: 8px;
}

.monitoring-teams-full-screen-button{
  padding: 4px  !important;
  border-bottom: 2px solid #F9A825 !important;
  border-radius: 0px  !important;

  &:hover {
    background-color: #F9A825 !important;
    border-radius: 5px  !important;
  }
}

.monitoring-teams-full-screen-header {
  margin: 16px 24px;
}

.monitoring-teams-exit-full-screen-button {
  padding: 4px  !important;
  border-radius: 8px  !important;
}