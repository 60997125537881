.react-flow__controls-button {
  height: 24px !important;
  width: 24px !important;
}

.react-flow__controls-button svg {
  max-height: 18px !important;
  max-width: 18px !important;
}

.node-hover {
  z-index: 1 !important;
}