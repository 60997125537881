.monitoring-tabs {
  #tabs {
    padding-top: 24px;
  }
  
  .tabs {
    .MuiTabPanel-root {
      padding: 0px 0;
    }

    .MuiButtonBase-root {
      padding: 0px;
    }
    
    .MuiTabs-flexContainer {
      gap: 50px;
    }

    .MuiContainer-root {
      padding: 0;
      margin: 0px;

      * {
        padding: 4px 0px 4px 0px;
        gap: 12px;
        margin: 0px;
      }

      .MuiChip-root {
        height: 22px;
        min-width: 25px !important;
        padding: 4px 8px 4px 8px;
        line-height: normal;
      }
    }
  }

  .MuiTabs-scroller::after {
    height: 4px !important;
  }

  .MuiTabs-indicator {
    height: 4px !important;
  }
}


.toggle-button {
  border: 1px solid #FDC431 !important;
  color: #444444 !important;

  &.Mui-selected {
    background-color: #FDC431 !important;
  }
}
