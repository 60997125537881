.tabs.MuiBox-root {
  padding-top: 20px;
}
.tabs .MuiTabs-indicator {
  height: 3.5px;
}
.tabs .MuiTabs-scroller:after {
  content: '';
  height: 3.5px;
  background: #C4C4C4;
  width: 100%;
  display: block;
}
.tabs .MuiButtonBase-root.MuiTab-root {
  color: #444444;
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
  padding: 10px;
}
.tabs .MuiButtonBase-root.Mui-selected {
  font-weight: 800;
}
.tabs .MuiTabPanel-root {
  padding: 30px 0;
}