.input-max-characteres {
  color: #D32F2F;
}

.main-section {
  width: 100%;
  margin-top: 48px;
}

.register-template-select-component {
  min-width: 15vw;
  max-width: 25vw;
}

.select-language-to-template {
  width: 10vw;
}

.input label {
  color: #98A2B3;
}

.error-text-template-already-exist,
.error-text-template-error {
  color: #D32F2F;
}