.two-factor-section{
  width: 50%;
  max-width: 960px;
  height: 60%;
  display: flex;
  flex-direction: column;
  padding: 2rem 7rem;
  border: 1px solid #c4c4c4;
  box-shadow: 1px 1px 50px 2px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  background: #fbfbfb;
}