.incoming-message {
  padding-right: 46px;
}

.incoming-message .baloon {
  background-color: #E3E8EE;
  color: #363636;
  padding: 12px 12px;
  border-radius: 12px;
  font-size: 17px;
}

.outgoing-message {
  padding-left: 46px;
}

.outgoing-message .baloon {
  background-color: #444444;;
  color: #F4F4F4;
  padding: 12px 12px;
  border-radius: 12px;
  font-size: 17px;
}

.baloon.button, .baloon.button-section, .baloon.list, .baloon.card, 
.baloon.location, .baloon.receipt, .baloon.attachment {
  width: 325px;
}

.baloon.card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.baloon.location a {
  text-decoration: none;
}

.baloon.receipt {
  color: #444444;
}
