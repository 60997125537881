.monitoring-page-table-paper {
  width: 100%;
  height: auto !important;
}

.MuiTable-root {
  border-collapse: separate;
  border-spacing: 0px 8px !important;
  table-layout: fixed
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom: none;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.monitoring-agents-protocol-table-row td {
  border-bottom: none;
  padding: 8px;
}

.monitoring-agent-protocols-table-column-max-width {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.monitoring-agent-protocols-table-header-cell {
  padding: 0 !important;
  margin-bottom: 16px;
  border-color: #FFFFFF !important;
  background-color: #FFFFFF;
}
