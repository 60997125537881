.login-title {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 5rem;
}

.title-decoration {
  display: flex;
  justify-content: flex-start;
}
