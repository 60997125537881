.rdrDayToday .rdrDayNumber span:after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: #FFBD4C;
}

.rdrPprevButton i {
  border-color: transparent #444 transparent transparent;
}

.rdrNextButton i {
  border-color: transparent transparent transparent #444;
}

.rdrCalendarWrapper.rdrDateRangeWrapper {
  width: 100%
}

.rdrMonth {
  width: 100%;
}

.rdrDayNumber span {
  color: black !important;
}

#popper-range-select {
  width: 100%;
}
