::-webkit-scrollbar {
  margin: 0px 12px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 5px;
  margin: 0px 12px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999;
}