.custom-scrollbar-large {
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-scrollbar-large::-webkit-scrollbar {
  width: 15px;
}

.custom-scrollbar-large::-webkit-scrollbar-track {
  background-color: #f2f3f2;
}

.custom-scrollbar-large::-webkit-scrollbar-thumb {
  background-color: #cdd3cd;
  border: 4px solid transparent;
  border-radius: 4px;
  background-clip: content-box;
  margin: 20px;
}

.custom-scrollbar-large::-webkit-scrollbar-thumb:hover {
  background-color: #babdba;
}