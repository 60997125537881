.table-container,
.table-main-container {
  border: none;
  box-shadow: none !important;
}

.table-head-row,
.table-head-body {
  padding: 16px;
}

.table-head-row {
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.table-head-body {
  color: #444;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 150px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-head-body-approved {
  border-radius: 16px;
}

.table-button-see-more {
  border: 0 !important;
  background-color: white !important;
  text-decoration: underline !important;
  color: #444444 !important;
}

.table-button-see-more:hover {
  cursor: pointer !important;
}
.delete-button, .copy-id-button {
  color: #FFBD4C !important;
}