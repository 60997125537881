.chatbot-card.MuiCard-root {
  background: #FBFBFB;
  border: 1.67038px solid rgba(22, 22, 23, 0.2);
  border-radius: 13.3631px;
  box-shadow: none;
  padding: 30px 20px;
}
.chatbot-card .MuiCardHeader-root {
  align-items: flex-start;
}
.chatbot-card .MuiCardHeader-root .MuiCardHeader-action .MuiButton-root {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #444444;
}
/* metrics */
.metric-card.MuiPaper-root {
  background: #FBFBFB;
  border: 0.435628px solid rgba(22, 22, 23, 0.2);
  border-radius: 4.6084px;
  box-shadow: none;
  height: 90px;
}
.metric-card.MuiPaper-root .MuiCardHeader-root {
  border-bottom: 0.435628px solid #CDCDCD;
}
.metric-card.MuiPaper-root .MuiCardHeader-root .MuiCardHeader-title {
  font-weight: 500;
  font-size: 14.8477px;
  line-height: 17px;
  color: #444444;
  text-align: center;
}
.metric-card .metric-count,
.metric-card .metric-count .metric-sub-percentage {
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: #444444;
  white-space: nowrap;
}
.metric-card .metric-count .metric-sub-percentage {
  font-size: 20px;
}
.metric-card .metric-label {
  font-weight: 500;
  font-size: 14.5209px;
  line-height: 17px;
  text-align: center;
  color: #444444;
}