.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFBD4C;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 12px;
}

.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 12px;
  width: 480px;
  height: auto;
}

.modal-content {
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: 550px;
  overflow-y: scroll;
  background-color: #EBE6DE;
  background-image: url("assets/images/whatsApp-background.png") !important;
}

.modal-circular-progress-box {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modal-footer {
  display: flex;
  gap: 16px;
  padding: 16px;
  justify-content: flex-end;
  background-color: #F8F8F8;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.modal-close-button {
  box-shadow: none;
  border-radius: 8px !important;
  color: #444444 !important;
  background-color: #FFBD4C !important;
}