.section-review-template {
  margin-top: 40px;
  margin-bottom: 40px;
}

.title-review {
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}

.whatsapp-background {
  background-image: url('assets/images/whatsApp-background.png') !important;
  width: 400px;
  height: 670px;
  overflow-y: scroll;
}

.content-review {
  padding: 16px 8px;
  border: 1px solid #D6DAE0;
  border-radius: 8px;
}

.content-template {
  background-color: #EEE6DC;
  padding: 32px 16px;
}

.message-simulator {
  background-color: #FEE6BC;
  width: 100%;
  height: 100%;
}

.topic-review { 
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  margin: 0;
}

.topic-data-review {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.button {
  background-color: white !important;
  border-radius: 10px !important;
}

.button:hover {
  background-color: white !important;
}

.button-review-template {
  background-color: #FFBD4C !important;
  color: #444444 !important;
  border-radius: 4px !important;
}