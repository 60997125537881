.monitoring-table-row *, .monitoring-table-row a, .monitoring-table-row td {
  border-bottom: none;
}

.monitoring-table-row-red *, .monitoring-table-row-red a, .monitoring-table-row-red td {
  color: #CD0000;
  border-bottom: none;
}

.monitoring-page-table-paper {
  width: 100%;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.monitoring-page-table-paper div[data-viewport-type="element"] {
  position: unset !important;
}

.monitoring-status-table-container {
  border-top: none;
}

.monitoring-status-table-column-max-width{
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.monitoring-status-table-column-text{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Sizes */

.monitoring-status-column-protocol-size {
  width: 195px;
}

.monitoring-status-column-sla-size {
  width: 90px;
}

.monitoring-status-table-header-cell {
  border-bottom: none !important;
  padding: 16px !important;
  background-color: #fff !important;
}