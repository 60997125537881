.message-content-constraints {
  padding: 4px;  
  border-radius: 8px;
  background-color: #F5F6F7;
  margin-bottom: 8px;
}

.message-content-contraints-text {
  font-size: 14px;
}

.constraint-item {  
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}