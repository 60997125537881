.second-step-preview-title {
  color: #98A2B3;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.whatsapp-background {
  background-image: url('assets/images/whatsApp-background.png') !important;
  height: 670px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.optional-label {
  color: #98A2B3;
  font-weight: 400;
  margin-left: 8px;
}

.button {
  padding: 8px;
}

.button-variable {
  background-color: #FFF2DB !important;
  padding: 4px !important;
  border-radius: 8px !important;
}

.button:hover {
  background-color: white;
}

.incorrect-variable-format {
  color: #D32F2F;
  font-size: 12px;
}