.monitoring-teams-error-message-title {
  display: flex;
  align-items: center;

  margin-bottom: 16px !important;

  color: #444444;
  font-size: 16px;
  font-weight: 600 !important;

  .monitoring-teams-error-message-title-icon {
    margin-right: 8px;
  }
}

.monitoring-teams-cards-error {
  margin-top: 16px;
  padding: 26px;
  background-color: #F5F6F7;
}