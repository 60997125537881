.sortableList {
  cursor: grabbing;
  pointer-events: auto !important;
}

.sortableHandle {
  cursor: grabbing;
  color: #8F8F8F;
  position: absolute;
  right: 10px;
}

.sortableItem {
  z-index: 4;
  position: relative;
  margin-bottom: 8px;
}

.sortableContainer {
  width: 100%;
}

.sortableHandle.disabled {
  cursor: not-allowed;
  pointer-events: unset;
  opacity: 0.4;
}