.attachment-container {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.attachment-section {
  flex-direction: row !important;
  justify-content: flex-end;
  align-items: center;
}