
.sign-up-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #fbfbfb;
}

.ubots-logo {
  position: absolute;
  top: 55px;
  left: 80px;
}

@media (max-width: 1100px) {
  .ubots-logo {
    left: 40px;
  }
}
