.DraftEditor-editorContainer,.DraftEditor-root,.public-DraftEditor-content {
  width: 100% !important;
  background-color: white !important;
  font-weight: 300;
  font-size: 14px;
  max-height: 70vh;
  height: inherit;
  position: relative;
}

.DraftEditor-root {
  overflow-y: auto;
}

.public-DraftEditor-content {
  cursor: text;
}

.DraftEditor-editorContainer {
  padding-top: 5px;
}

.DraftEditor-root::-webkit-scrollbar {
  width: 15px;
}

 .DraftEditor-root::-webkit-scrollbar-track {
  background-color: #f2f3f2;
}

 .DraftEditor-root::-webkit-scrollbar-thumb {
  background-color: #cdd3cd;
  border: 4px solid transparent;
  border-radius: 4px;
  background-clip: content-box;
  margin: 20px;

}

 .DraftEditor-root::-webkit-scrollbar-thumb:hover {
  background-color: #babdba;
}

.drawer-text-input .DraftEditor-root {
  max-height: 100px;
  overflow-y: auto;
}

.text-component-baloon.focus .DraftEditor-editorContainer {
  padding-right: 20px;
}

.text-component-baloon :not(.focus) ul {
  margin: 0px;
}





