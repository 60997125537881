.monitoring-teams-simplified-cards {
  display: grid !important;
  column-gap: 24px;
  row-gap: 24px;
  margin: 16px;
}

.monitoring-teams-simplified-cards-6 {
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.monitoring-teams-simplified-cards-12 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.monitoring-teams-full-screen-pagination {
  padding: 0px 16px 16px 16px !important;
}

.monitoring-teams-full-screen-pagination-button {
  padding: 4px !important;
}