.chatbot-card-disabled.MuiCard-root {
  background: rgba(68, 68, 68, 0.05);;
  border: 1.67038px solid rgba(22, 22, 23, 0.2);
  border-radius: 13.3631px;
  box-shadow: none;
  padding: 20px 15px;
}
.chatbot-card-disabled .MuiCardHeader-root {
  padding: 0;
  align-items: flex-start;
}
.chatbot-card-disabled .MuiCardHeader-root .MuiCardHeader-action .MuiButton-root {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #444444;
}