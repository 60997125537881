.search-chatbots-autocomplete .MuiOutlinedInput-root,
.search-chatbots-autocomplete .MuiOutlinedInput-root:hover {
  border-radius: 5px;
}
.search-chatbots-autocomplete .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 100%;
  width: fit-content !important;
}
.search-chatbots-autocomplete .MuiAutocomplete-popupIndicator {
  transform: none !important;
}
.search-chatbots-autocomplete  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #C4C4C4;
}
.search-chatbots-autocomplete .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #FFAB40
}
.search-chatbots-autocomplete .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #FFAB40;
}