.evaluation-button {
  padding: .2rem 1.2rem !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  text-align: center !important;
}

.evaluation-button.positive {
  --green: #00C247;
  color: var(--green);
  border-color: var(--green);
  background-color: none;
}


.evaluation-button.positive.disabled {
  --grey: #E2E2E2;
  color: var(--grey);
  border-color: var(--grey);
}
.evaluation-button.positive:hover {
  --green: #00C247;
  color: white;
  background-color: var(--green);
}

.evaluation-button.positive.active {
  --green: #00C247;
  color: white;
  background-color: var(--green);
}

.evaluation-button.positive.active.disabled {
  --grey: #E2E2E2;
  color: white;
  border-color: var(--grey);
  background-color: var(--grey)!important;
}

.evaluation-button.positive.active:hover {
  --green: #00C247;
  color: white;
  background-color: var(--green);
}

.evaluation-button.negative {
  --red: #FF3333;
  color: var(--red);
  border-color: var(--red);
  background-color: none;
}

.evaluation-button.negative.disabled {
  --grey: #E2E2E2;
  color: var(--grey);
  border-color: var(--grey);
}

.evaluation-button.negative:hover {
  --red: #FF3333;
  color: white;
  background-color: var(--red);
}

.evaluation-button.negative.active {
  --red: #FF3333;
  color: white;
  background-color: var(--red);
}

.evaluation-button.negative.active.disabled {
  --grey: #E2E2E2;
  color: white;
  background-color: var(--grey)!important;
}

.evaluation-button.negative.active:hover {
  --red: #FF3333;
  color: white;
  background-color: var(--red);
}

