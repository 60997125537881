.react-flow__edge-path { 
  stroke: #676767 !important; 
  stroke-width: 3px !important
}

.react-flow__edge-custom path:first-child {
  stroke: transparent;
  stroke-width: 15;
}

.react-flow__edge-custom:hover path:first-child {
  stroke: rgba(255, 189, 76, 0.3);
  stroke-width: 10;
}

.react-flow__edge-custom:hover path:not(:first-child) {
  marker-end: url('#highlightedArrow');
}

.react-flow__edge-custom.selected path:first-child {
  stroke: rgba(255, 189, 76, 0.3);
  stroke-width: 10;
}