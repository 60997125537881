.container-main {
  padding: 2%;
}

.search-input-component {
  border: #D6DAE0 !important;
}

.search-input-component label {
  color: #98A2B3;
  font-weight: 400;
}

.btn-new-template-component{
  background-color: #FFBD4C !important;
  color: #444 !important;
  font-size: 15px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 14px !important;
  padding-top: 14px !important;
  white-space: nowrap;
}