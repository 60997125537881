.monitoring-agents-table-column-max-width {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.monitoring-agents-table-header-cell {
  border-color: #D0D0D1 !important;
}

.monitoring-agents-table-column-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.monitoring-agent-table-row-filter {
  width:100%;
  padding-bottom: 30px;
}

.monitoring-agents-table-container {
  border-top: 1px solid #D0D0D1;

  #monitoring-agents-table-head th {
    background-color: #FFF !important;

    border: none;
  }
}

.monitoring-agents-row:hover {
  cursor: pointer;
  background-color: #EAECF0;
}

.monitoring-agents-row.disabled {
  cursor: default;
  background-color: #FFF;
}