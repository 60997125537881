.message-and-attachment {
  background-color: #E8FFDC;
  border-radius: 8px;
  overflow-wrap: break-word;
  max-width: 80%;
  margin-right: 18px;
}

.message-content {
  padding: 6px 12px;
}

.whatsapp-pointer {
  transform: rotate(270deg);
  margin-right: -24px;
}

.message-time {
  color: #7E7E7E;
}