.container {
  display: flex;
  height: 100vh;
}

.login-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  padding: 5rem 13rem;
}

.login-form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sign-up-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

@media (max-width: 1439px) {
  .login-section {
    padding: 3.75rem 7rem;
  }

  .login-title {
    margin-bottom: 2.5rem;
  }

  .sign-up-text {
    margin-top: 2.5rem;
  }

}
