.new-template-config {
  margin-left: 32px !important;
  margin-right: 32px !important;
  margin-top: 16px !important;
  overflow-y: scroll;
}

.button-disabled-next {
  background-color: #CBD0D9 !important;
  color: #444444 !important;
  border-radius: 4px;
}

.button-enabled-next {
  background-color: #FFBD4C !important;
  color: #444444 !important;
  border-radius: 4px;
}

