.help-button {
    border-radius: 24px !important;
    padding: 12px !important;
    gap: 4px !important;
    float: right;
}

.help-text {
    display: flex;
    align-items: center;
}
  
.help-icon {
    margin-right: 0.2em;
}