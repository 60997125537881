.delete-template-modal-header {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  background-color: #FFBD4C;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 16px 24px;
}

.delete-template-modal-header-label {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.delete-template-modal-content-label {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.delete-template-modal-content {
  padding: 16px 24px;
  background-color: white;
}

.delete-template-modal-footer {
  display: flex;
  gap: 16px;
  padding: 24px 24px;
  justify-content: flex-end;
  background-color: #F8F8F8;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.delete-template-modal-footer-button {
  color: #444444 !important;
}