.outlined-button {
  border-color: #FFBD4C !important; 
}

.contained-button {
  background-color: #FFBD4C !important;
  color: #444444 !important;
}

.attachment-background {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  background-color: #F5F6F7;
  width: 550px;
  border-radius: 8px;
  padding-right: 8px;
  padding-left: 8px;
}